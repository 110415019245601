import {
  Contact,
  CoreContent,
  Introduce,
  MainContent,
  Strategy,
} from "../components/desktop";
import { MainWrapper } from "../components/style";

export const DesktopQuantFolio = () => {
  return (
    <MainWrapper>
      <MainContent />
      <Introduce />
      <CoreContent />
      <Strategy />
      <Contact />
    </MainWrapper>
  );
};
