import {
  CoreBgWrapper,
  CoreInnerContent,
  CoreInnerItem,
  CoreInnerTitle,
  DesktopCoreItem,
  CoreList,
  CoreTitle,
  CoreWrapper,
} from "../style";

export const CoreContent = () => {
  return (
    <CoreWrapper>
      <CoreTitle>Core strategies</CoreTitle>
      <CoreList>
        <DesktopCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Quant & Machine Learning Based</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </DesktopCoreItem>
        <DesktopCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Systematic Long/Short</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </DesktopCoreItem>
        <DesktopCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Market Making</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </DesktopCoreItem>
        <DesktopCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Low-to-High Frequency Trading</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </DesktopCoreItem>
      </CoreList>
    </CoreWrapper>
  );
};
