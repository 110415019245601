import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import {
  FixedHeaderContainer,
  HeaderLogo,
  HeaderMenu,
  HeaderWrapper,
  TabletHeaderContainer,
  TabletMenuBtn,
} from "../style";
import { HeaderProps } from "./HeaderContainer";

export const TabletHeader: FC<HeaderProps> = ({ isFixed, onOpenWidget }) => {
  const Wrapper = isFixed ? FixedHeaderContainer : HeaderWrapper;
  const LogoPath = isFixed
    ? "/img/quantfolio_logo_color.png"
    : "/img/quantfolio_logo_white.png";
  return (
    <Wrapper>
      <TabletHeaderContainer>
        <HeaderLogo>
          <a href=".">
            <img width={120} height={24} src={LogoPath} alt="Quantfolio Logo" />
          </a>
        </HeaderLogo>
        <HeaderMenu>
          <TabletMenuBtn onClick={onOpenWidget}>
            <FontAwesomeIcon icon={faBars} size="lg" inverse={!isFixed} />
          </TabletMenuBtn>
        </HeaderMenu>
      </TabletHeaderContainer>
    </Wrapper>
  );
};
