import styled from "styled-components";

export const WidgetWrapper = styled.div`
  position: fixed;
  width: 400px;
  max-width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #000000;
  z-index: 1000;

  &.show {
    right: 0;
    transition: right 0.33s ease-in;
  }

  &.hide {
    right: -400px;

    transition: right 0.33s ease-in;
  }
`;
export const WidgetHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;
export const WidgetCloseBtn = styled.button``;
export const WidgetMenuList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;
export const WidgetMenuItem = styled.a`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  padding: 20px 0;
`;
