import {
  ContentBgImage,
  ContentBgWrapper,
  ContentInnerItem,
  ContentInnerTitle,
  ContentInnerWrapper,
  ContentLinkAbout,
  MainContentWrapper,
} from "../style/mainContent.style";

export const MainContent = () => {
  return (
    <MainContentWrapper>
      <ContentBgWrapper>
        <ContentBgImage />
      </ContentBgWrapper>
      <ContentInnerWrapper>
        <ContentInnerItem>
          <ContentInnerTitle>
            <span>Data, Digital, Trading,</span>
            <span>&</span>
            <br />
            <span>Technology</span>
          </ContentInnerTitle>
          <ContentLinkAbout href="#about">
            <div>Discover More</div>
            <div>{`>`}</div>
          </ContentLinkAbout>
        </ContentInnerItem>
      </ContentInnerWrapper>
    </MainContentWrapper>
  );
};
