import {
  ContentBgImage,
  ContentBgWrapper,
  ContentInnerItem,
  MainContentWrapper,
  MobileContentInnerWrapper,
  MobileContentLinkAbout,
  TabletContentInnerTitle,
} from "../style/mainContent.style";

export const MainContent = () => {
  return (
    <MainContentWrapper>
      <ContentBgWrapper>
        <ContentBgImage />
      </ContentBgWrapper>
      <MobileContentInnerWrapper>
        <ContentInnerItem>
          <TabletContentInnerTitle>
            <span>Data, Digital, Trading,</span>
            <span>&</span>
            <br />
            <span>Technology</span>
          </TabletContentInnerTitle>
          <MobileContentLinkAbout href="#about">
            <div>Discover More</div>
            <div>{`>`}</div>
          </MobileContentLinkAbout>
        </ContentInnerItem>
      </MobileContentInnerWrapper>
    </MainContentWrapper>
  );
};
