import styled from "styled-components";

export const FullBackground = styled.div`
  margin-left: -50vw;
  left: 50%;
  width: 100vw;
`;

export const HeadingTwo = styled.h2`
  font-size: 44px;
  line-height: 54px;

  @media (max-width: 1300px) {
    font-size: 37.4px;
    line-height: 45.9px;
  }

  @media (max-width: 1000px) {
    font-size: 33px;
    line-height: 40.5px;
  }

  @media (max-width: 690px) {
    font-size: 26.4px;
    line-height: 32.4px;
  }
`;
export const MainWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 90px;
`;

export const TabletMainWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const MobileMainWrapper = styled.div`
  max-width: 420px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 480px) {
    max-width: 320px;
  }
`;
