import {
  CoreBgWrapper,
  CoreInnerContent,
  CoreInnerItem,
  CoreInnerTitle,
  CoreTitle,
  MobileCoreItem,
  MobileCoreWrapper,
  TabletCoreList,
} from "../style";

export const CoreContent = () => {
  return (
    <MobileCoreWrapper>
      <CoreTitle>Core strategies</CoreTitle>
      <TabletCoreList>
        <MobileCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Quant & Machine Learning Based</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </MobileCoreItem>
        <MobileCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Systematic Long/Short</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </MobileCoreItem>
        <MobileCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Market Making</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </MobileCoreItem>
        <MobileCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Low-to-High Frequency Trading</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </MobileCoreItem>
      </TabletCoreList>
    </MobileCoreWrapper>
  );
};
