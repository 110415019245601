/* eslint-disable react-hooks/rules-of-hooks */
import {
  WidgetCloseBtn,
  WidgetHeader,
  WidgetMenuItem,
  WidgetMenuList,
  WidgetWrapper,
} from "../style";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsMobile, useIsTablet } from "../../utils/DeviceDetectContext";
import { FC } from "react";

interface WidgetProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Widget: FC<WidgetProps> = ({ isOpen, onClose }) => {
  if (useIsMobile() || useIsTablet()) {
    return (
      <WidgetWrapper className={isOpen ? "show" : "hide"}>
        <WidgetHeader>
          <WidgetCloseBtn onClick={onClose}>
            <FontAwesomeIcon icon={faClose} size="2x" inverse />
          </WidgetCloseBtn>
        </WidgetHeader>
        <WidgetMenuList>
          <WidgetMenuItem href="#about" onClick={onClose}>
            About
          </WidgetMenuItem>
          <WidgetMenuItem href="#contact" onClick={onClose}>
            Contact
          </WidgetMenuItem>
        </WidgetMenuList>
      </WidgetWrapper>
    );
  }

  return <></>;
};
