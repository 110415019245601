import styled from "styled-components";

export const FixedHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  color: #000000;
  z-index: 100;
  background-color: #ffffff;

  .container {
    height: 100%;
    max-width: 1500px;
    margin: 16px auto;
    padding: 0 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-menu {
    & > div {
    }

    a {
      color: #000000;
      font-weight: 700;
    }
  }
`;

export const HeaderContainer = styled.div`
  margin: 0 auto;
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DesktopHeaderContainer = styled(HeaderContainer)`
  max-width: 1500px;
  margin: 34px auto;
  padding: 0 90px;

  @media (max-width: 1000px) {
    margin: 0 auto;
    padding: 12px 0;
    max-width: 600px;
  }
`;

export const TabletHeaderContainer = styled(HeaderContainer)`
  max-width: 600px;
  padding: 12px 0;
`;

export const HeaderLogo = styled.div`
  z-index: 1;
  height: 100%;

  & > a {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
export const HeaderMenu = styled.div`
  display: flex;
`;

export const HeaderMenuItem = styled.div`
  font-size: 16px;
  & > a {
    display: flex;
    align-items: center;
    margin: auto 26px;
    color: #ffffff;
  }

  &:last-child > a {
    margin-right: 0;
  }
`;

export const TabletMenuBtn = styled.button``;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  color: #ffffff;
  z-index: 100;

  &::before {
    display: block;
    content: " ";
    position: absolute;
    pointer-events: none;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.04) 16%,
      rgba(0, 0, 0, 0.23) 75%,
      rgba(0, 0, 0, 0.33) 100%
    );
    height: 120%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
`;
