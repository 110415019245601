import styled from "styled-components";
import { FullBackground } from "./common.style";

export const MainContentWrapper = styled.div`
  position: relative;
  padding-top: 15vw;
  padding-bottom: 13vw;
`;

export const ContentBgWrapper = styled(FullBackground)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
`;

export const ContentBgImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/img/quantfolio1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #d1d1ca;
  background-size: cover;
`;

export const ContentInnerWrapper = styled.div`
  display: flex;
  color: #ffffff;
`;

export const MobileContentInnerWrapper = styled(ContentInnerWrapper)`
  padding-top: 34px;
`;

export const ContentInnerItem = styled.div`
  & > a {
    color: #ffffff;
  }
`;

export const ContentInnerTitle = styled.h2`
  font-size: 62px;
  line-height: 77px;
  margin-bottom: 70px;

  @media (max-width: 1300px) {
    font-size: 46.5px;
    line-height: 57.75px;
  }
`;

export const TabletContentInnerTitle = styled.h2`
  font-size: 43.4px;
  line-height: 53.9px;
  margin-bottom: 70px;
`;

export const MobileContentInnerTitle = styled.h2`
  font-size: 40.3px;
  line-height: 50.05px;
  margin-bottom: 70px;
`;

export const ContentLinkAbout = styled.a`
  display: flex;
  align-items: center;

  & > div {
    font-weight: 700;
    margin-right: 12px;
  }
`;

export const MobileContentLinkAbout = styled(ContentLinkAbout)`
  margin-bottom: 25px;

  & > div {
    font-size: 16.2px;
    line-height: 25.2px;
  }
`;
