import styled from "styled-components";
import { HeadingTwo } from "./common.style";

export const MobileCoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10vw;
`;

export const CoreWrapper = styled(MobileCoreWrapper)`
  margin: 0 -20px;
`;

export const CoreTitle = styled(HeadingTwo)`
  margin-bottom: 40px;
  text-align: center;
`;

export const TabletCoreTitle = styled(CoreTitle)`
  margin-bottom: 60px;
`;

export const CoreList = styled.div`
  display: flex;
`;

export const TabletCoreList = styled(CoreList)`
  flex-wrap: wrap;
`;

const CoreItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 500px;

  &:nth-child(1) .bg-color {
    background-image: url("/img/quantfolio_core_1.png");
  }
  &:nth-child(2) .bg-color {
    background-image: url("/img/quantfolio_core_2.png");
  }
  &:nth-child(3) .bg-color {
    background-image: url("/img/quantfolio_core_3.png");
  }
  &:nth-child(4) .bg-color {
    background-image: url("/img/quantfolio_core_4.png");
  }
`;

export const DesktopCoreItem = styled(CoreItem)`
  padding: 0 20px;
`;

export const TabletCoreItem = styled(CoreItem)`
  width: 50%;
  padding: 0 20px;
  margin-bottom: 40px;
`;

export const MobileCoreItem = styled(CoreItem)`
  width: 100%;
  margin-bottom: 25px;
`;

export const CoreInnerItem = styled.div`
  background-color: #eeeeee;
  height: 100%;
  position: relative;

  ::before {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 85%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(15, 15, 15, 0),
      rgba(15, 15, 15, 0.75) 100%
    );
  }
`;

export const CoreBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CoreInnerContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 11%;
`;

export const CoreInnerTitle = styled.h4`
  color: #ffffff;
  line-height: 28px;
`;
