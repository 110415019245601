import {
  Contact,
  CoreContent,
  Introduce,
  MainContent,
  Strategy,
} from "../components/mobile";
import { MobileMainWrapper } from "../components/style";

export const MobileQuantFolio = () => {
  return (
    <MobileMainWrapper>
      <MainContent />
      <Introduce />
      <CoreContent />
      <Strategy />
      <Contact />
    </MobileMainWrapper>
  );
};
