/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Footer, HeaderContainer as Header, Widget } from "./layouts";
import MainPage from "./MainPage";

function App() {
  const [isTop, setIsTop] = useState(true);
  const [isOpenWidget, setIsOpenWidget] = useState(false);

  // TODO(circle): 스크롤 이벤트 최적화 진행하기 (스로틀링 활용해서)
  const handleScroll = () => {
    if (window.scrollY === 0) setIsTop(true);
    else if (isTop && window.scrollY !== 0) setIsTop(false);
  };

  const handleCloseWidget = () => {
    setIsOpenWidget(false);
  };

  const handleOpenWidget = () => {
    setIsOpenWidget(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header isFixed={!isTop} onOpenWidget={handleOpenWidget} />
      <MainPage />
      <Footer />
      <Widget isOpen={isOpenWidget} onClose={handleCloseWidget} />
    </>
  );
}

export default App;
