import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { InitializeGlobalStyle } from "./IndexStyle";
import { DeviceDetectProvider } from "./utils/DeviceDetectContext";

ReactDOM.render(
  <React.StrictMode>
    <DeviceDetectProvider>
      <InitializeGlobalStyle />
      <App />
    </DeviceDetectProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
