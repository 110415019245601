import {
  FooterTermsWrapper,
  FooterWrapper,
  MobileFooterInnerWrapper,
  MobileFooterTermsItem,
} from "../style";

export const MobileFooter = () => {
  return (
    <FooterWrapper>
      <FooterTermsWrapper>
        <MobileFooterTermsItem>
          <a
            href="https://static.rabit.bot/en/terms_of_service.html"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </MobileFooterTermsItem>
        <MobileFooterTermsItem>
          <a
            href="https://static.rabit.bot/en/privacy_policy.html"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
        </MobileFooterTermsItem>
        <MobileFooterTermsItem>
          <a
            href="https://static.rabit.bot/en/notice_of_trading_risk.html"
            target="_blank"
            rel="noreferrer"
          >
            Trading Risk
          </a>
        </MobileFooterTermsItem>
      </FooterTermsWrapper>
      <MobileFooterInnerWrapper>
        Copyright © 2020 quantfolio
        <br />
        All rights reserved.
      </MobileFooterInnerWrapper>
    </FooterWrapper>
  );
};
