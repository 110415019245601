import styled from "styled-components";
import { FullBackground } from "./common.style";

export const ContactUs = styled.div`
  position: relative;
  color: #ffffff;
`;

export const ContactBgWrapper = styled(FullBackground)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
`;

export const ContactBgOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    rgba(25, 88, 216, 0.82) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
`;

export const ContactBgImage = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(https://source.unsplash.com/faAVWMeB3dw);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #333333;
  background-size: cover;
`;

export const ContactInnerWrapper = styled.div`
  padding: 10vw 0;

  display: flex;
`;

export const ContactLocation = styled.p`
  margin-top: 150px;
  font-weight: 300;
`;

export const ContactInnerBox = styled.div`
  & > div {
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: 300;
  }

  & > .headquarter {
    font-size: 15px;
  }
`;

export const DesktopContactInnerBox = styled(ContactInnerBox)`
  padding: 0 20px;
  width: 50%;
`;
