import styled from "styled-components";

export const FooterWrapper = styled.div`
  background-color: #000000;
  padding: 60px 0;
  color: #ffffff;
  font-weight: 300;
  font-family: "Roboto";
`;

export const FooterInnerWrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 0 90px;
  text-align: center;
`;

export const MobileFooterInnerWrapper = styled(FooterInnerWrapper)`
  padding: 0 45px;
`;

export const FooterTermsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const DesktopFooterTermsWrapper = styled(FooterTermsWrapper)`
  padding: 0 90px;
`;

export const FooterTermsItem = styled.div`
  & > a {
    color: #ffffff;
  }

  ::after {
    display: inline-block;
    content: "|";
    margin: 0 30px;
  }

  &:first-child {
    ::before {
      display: inline-block;
      content: "|";
      margin: 0 30px;
    }
  }
`;

export const MobileFooterTermsItem = styled(FooterTermsItem)`
  ::after {
    margin: 0 16px;
  }

  &:first-child {
    ::before {
      margin: 0 16px;
    }
  }
`;
