import {
  CoreBgWrapper,
  CoreInnerContent,
  CoreInnerItem,
  CoreInnerTitle,
  CoreWrapper,
  TabletCoreItem,
  TabletCoreList,
  TabletCoreTitle,
} from "../style";

export const CoreContent = () => {
  return (
    <CoreWrapper>
      <TabletCoreTitle>Core strategies</TabletCoreTitle>
      <TabletCoreList>
        <TabletCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Quant & Machine Learning Based</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </TabletCoreItem>
        <TabletCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Systematic Long/Short</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </TabletCoreItem>
        <TabletCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Market Making</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </TabletCoreItem>
        <TabletCoreItem>
          <CoreInnerItem>
            <CoreBgWrapper className="bg-color"></CoreBgWrapper>
            <CoreInnerContent>
              <CoreInnerTitle>Low-to-High Frequency Trading</CoreInnerTitle>
            </CoreInnerContent>
          </CoreInnerItem>
        </TabletCoreItem>
      </TabletCoreList>
    </CoreWrapper>
  );
};
