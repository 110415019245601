import {
  FooterInnerWrapper,
  FooterTermsItem,
  DesktopFooterTermsWrapper,
  FooterWrapper,
} from "../style";

export const DesktopFooter = () => {
  return (
    <FooterWrapper>
      <DesktopFooterTermsWrapper>
        <FooterTermsItem>
          <a
            href="https://static.rabit.bot/en/terms_of_service.html"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </FooterTermsItem>
        <FooterTermsItem>
          <a
            href="https://static.rabit.bot/en/privacy_policy.html"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
        </FooterTermsItem>
        <FooterTermsItem>
          <a
            href="https://static.rabit.bot/en/notice_of_trading_risk.html"
            target="_blank"
            rel="noreferrer"
          >
            Trading Risk
          </a>
        </FooterTermsItem>
      </DesktopFooterTermsWrapper>
      <FooterInnerWrapper>
        Copyright © 2020 quantfolio
        <br />
        All rights reserved.
      </FooterInnerWrapper>
    </FooterWrapper>
  );
};
