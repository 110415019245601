import {
  HeadingTwo,
  IntroduceBgImage,
  IntroduceTextWrapper,
  MobileIntroduceImageWrapper,
  MobileIntroduceWrapper,
} from "../style";

export const Introduce = () => {
  return (
    <MobileIntroduceWrapper id="about">
      <MobileIntroduceImageWrapper>
        <IntroduceBgImage />
      </MobileIntroduceImageWrapper>
      <IntroduceTextWrapper>
        <HeadingTwo>Quantfolio is</HeadingTwo>
        <p>
          Quantfolio is a fintech company that develops solutions necessary for
          AI-driven asset management process such as competitive data, AI model,
          portfolio management system, and trading system necessary to perform
          quantitative asset management, with its own technology, and applies
          them to capital market & crypto currency market.
        </p>
        <br />
        <p>
          Quantfolio operates several funds by supplying various asset
          management models for spot and derivative products and IT solutions
          necessary to manage them—e.g., latency arbitrage, statistical
          arbitrage, and machine learning model-based transactions.
        </p>
        <br />
        <p>
          Currently, it provides discretionary account asset management service
          based on system trading in the customer’s own account through the API
          link.
        </p>
        <br />
        <p>
          It is composed of a specialized R&D team for structured/unstructured
          (typically text) financial big data analysis processing,
          quantitative/statistical investment model development, execution model
          development, and ultra-low latency trading system development.
        </p>
      </IntroduceTextWrapper>
    </MobileIntroduceWrapper>
  );
};
