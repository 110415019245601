import { FC } from "react";
import {
  HeaderWrapper,
  DesktopHeaderContainer,
  HeaderLogo,
  HeaderMenu,
  HeaderMenuItem,
  FixedHeaderContainer,
} from "../style";
import { HeaderProps } from "./HeaderContainer";

export const DesktopHeader: FC<HeaderProps> = ({ isFixed }) => {
  const Wrapper = isFixed ? FixedHeaderContainer : HeaderWrapper;
  const LogoPath = isFixed
    ? "/img/quantfolio_logo_color.png"
    : "/img/quantfolio_logo_white.png";
  return (
    <Wrapper>
      <DesktopHeaderContainer className="container">
        <HeaderLogo>
          <a href=".">
            <img width={120} height={24} src={LogoPath} alt="Quantfolio Logo" />
          </a>
        </HeaderLogo>
        <HeaderMenu className="header-menu">
          <HeaderMenuItem>
            <a href="#about">About </a>
          </HeaderMenuItem>
          <HeaderMenuItem>
            <a href="#contact">Contact </a>
          </HeaderMenuItem>
        </HeaderMenu>
      </DesktopHeaderContainer>
    </Wrapper>
  );
};
