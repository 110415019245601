import { FC } from "react";
import { withAdaptiveRender } from "../../utils/hoc/withAdaptiveRender";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";
import { TabletHeader } from "./TabletHeader";

const Header = withAdaptiveRender({
  desktop: DesktopHeader,
  tablet: TabletHeader,
  mobile: MobileHeader,
});

export interface HeaderProps {
  isFixed: boolean;
  onOpenWidget?: () => void;
}

export const HeaderContainer: FC<HeaderProps> = (props) => {
  return <Header {...props} />;
};
