import { createContext, FC, useContext, useEffect, useState } from "react";

function getIsMobile(): boolean {
  return window.innerWidth < 691;
}

function getIsTablet(): boolean {
  return window.innerWidth >= 691 && window.innerWidth <= 1000;
}

export const DeviceDetectContext = createContext([
  getIsMobile(),
  getIsTablet(),
]);

const { Provider: DeviceDetectContextProvider } = DeviceDetectContext;

export const DeviceDetectProvider: FC = ({ children }) => {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isTablet, setIsTablet] = useState(getIsTablet());

  useEffect(() => {
    const mqMobile = window.matchMedia(`screen and (max-width: 690px)`);
    const mqTablet = window.matchMedia(
      `screen and (min-width: 691px) and (max-width: 1000px)`
    );

    const handleResizeForMobile = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };
    const handleResizeForTablet = (e: MediaQueryListEvent) => {
      setIsTablet(e.matches);
    };

    mqMobile.addEventListener("change", handleResizeForMobile);
    mqTablet.addEventListener("change", handleResizeForTablet);

    return () => {
      mqMobile.removeEventListener("change", handleResizeForMobile);
      mqTablet.removeEventListener("change", handleResizeForTablet);
    };
  }, []);

  return (
    <DeviceDetectContextProvider value={[isMobile, isTablet]}>
      {children}
    </DeviceDetectContextProvider>
  );
};

export const useIsMobile = () => useContext(DeviceDetectContext)[0];
export const useIsTablet = () => useContext(DeviceDetectContext)[1];
