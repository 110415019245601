import { TabletMainWrapper } from "../components/style";
import {
  Contact,
  CoreContent,
  Introduce,
  MainContent,
  Strategy,
} from "../components/tablet";

export const TabletQuantFolio = () => {
  return (
    <TabletMainWrapper>
      <MainContent />
      <Introduce />
      <CoreContent />
      <Strategy />
      <Contact />
    </TabletMainWrapper>
  );
};
