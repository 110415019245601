import {
  ContactBgImage,
  ContactBgOverlay,
  ContactBgWrapper,
  ContactInnerBox,
  ContactInnerWrapper,
  ContactLocation,
  ContactUs,
  HeadingTwo,
} from "../style";

export const Contact = () => {
  return (
    <ContactUs id="contact">
      <ContactBgWrapper>
        <ContactBgImage />
        <ContactBgOverlay />
      </ContactBgWrapper>
      <ContactInnerWrapper>
        <ContactInnerBox>
          <HeadingTwo>Contact US</HeadingTwo>
          <div>info@quantfolio.io</div>
          <p className="headquarter">Quantfolio (Headquarters in Singapore)</p>
          <ContactLocation>
            160 Robinson Road #24 #738, Singapore
          </ContactLocation>
        </ContactInnerBox>
      </ContactInnerWrapper>
    </ContactUs>
  );
};
