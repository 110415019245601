import React, { ComponentType, FC } from "react";
import { AdaptiveRenderSettingModel } from "../../models";
import { useIsMobile, useIsTablet } from "../DeviceDetectContext";

export function withAdaptiveRender<T>(settings: AdaptiveRenderSettingModel<T>) {
  const FnComp: FC<T> = (props) => {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    const {
      desktop: DesktopComp,
      tablet: TabletComp,
      mobile: MobileComp,
    } = settings;

    let Comp: FC<T> | ComponentType<T>;

    if (isMobile && MobileComp) {
      Comp = MobileComp;
    } else if (isTablet && TabletComp) {
      Comp = TabletComp;
    } else if (!isMobile && !isTablet && DesktopComp) {
      Comp = DesktopComp;
    } else {
      return null;
    }

    return <Comp {...props} />;
  };

  return FnComp;
}
