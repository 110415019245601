import styled from "styled-components";

export const StrategyWrapper = styled.div`
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  padding-bottom: 10vw;
`;

export const DesktopStrategyWrapper = styled(StrategyWrapper)`
  display: flex;
  flex-wrap: wrap;
`;

export const StrategyItem = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DesktopStrategyItem = styled(StrategyItem)`
  width: 50%;
`;

export const TabletStrategyItem = styled(StrategyItem)`
  width: 100%;
  min-height: 64vw;
`;
export const MobileStrategyItem = styled(StrategyItem)`
  width: 100%;
  min-height: 100vw;
`;

export const FirstItem = styled(DesktopStrategyItem)`
  background-image: url("/img/quantfolio_content_1.png");
`;

export const SecondItem = styled(DesktopStrategyItem)`
  background-image: url("/img/quantfolio_content_2.png");
`;

export const ThirdItem = styled(DesktopStrategyItem)`
  background-image: url("/img/quantfolio_content_3.png");
`;

export const TabletFirstItem = styled(TabletStrategyItem)`
  background-image: url("/img/quantfolio_content_1.png");
`;

export const TabletSecondItem = styled(TabletStrategyItem)`
  background-image: url("/img/quantfolio_content_2.png");
`;

export const TabletThirdItem = styled(TabletStrategyItem)`
  background-image: url("/img/quantfolio_content_3.png");
`;

export const MobileFirstItem = styled(MobileStrategyItem)`
  background-image: url("/img/quantfolio_content_1.png");
`;

export const MobileSecondItem = styled(MobileStrategyItem)`
  background-image: url("/img/quantfolio_content_2.png");
`;

export const MobileThirdItem = styled(MobileStrategyItem)`
  background-image: url("/img/quantfolio_content_3.png");
`;

export const StrategyItemDesc = styled.div`
  width: 50%;
  padding: 10vw;

  & > h4 {
    color: #566fff;
    margin-bottom: 12px;
  }

  & > h2 {
    font-size: 40px;
    line-height: 50px;
  }

  & > div {
    font-weight: 700;
    margin: 20px 0;
  }

  & > p {
    color: rgba(10, 10, 10, 0.6);
    font-weight: 300;
  }
`;

export const TabletStrategyItemDesc = styled.div`
  padding: 10vw;
  width: 100%;

  & > h4 {
    color: #566fff;
    margin-bottom: 12px;
    font-size: 16.2px;
  }

  & > h2 {
    font-size: 33px;
    line-height: 40.5px;
  }

  & > div {
    font-weight: 700;
    margin: 20px 0;
  }

  & > p {
    color: rgba(10, 10, 10, 0.6);
    font-weight: 300;
  }
`;

export const MobileStrategyItemDesc = styled.div`
  padding: 10vw;
  width: 100%;

  & > h4 {
    color: #566fff;
    margin-bottom: 12px;
  }

  & > h2 {
    font-size: 26.4px;
    line-height: 32.4px;
  }

  & > div {
    font-weight: 700;
    margin: 20px 0;
  }

  & > p {
    color: rgba(10, 10, 10, 0.6);
    font-weight: 300;
  }
`;
