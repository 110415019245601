import {
  FirstItem,
  SecondItem,
  StrategyItemDesc,
  DesktopStrategyWrapper,
  ThirdItem,
} from "../style";

export const Strategy = () => {
  return (
    <DesktopStrategyWrapper>
      <FirstItem />
      <StrategyItemDesc>
        <h4>AI-Driven Digital Asset Management</h4>
        <h2>Key Elements and Competitiveness of AI Strategy (1)</h2>
        <div>AI Technology-Based Prediction Strategy Implementation</div>
        <p>
          • Creates over 200 investment signals that follow the path of price
          fluctuation using quantitative, machine learning models <br />
          • AI serves the role of generating the weight of investment signals
          and operating them by actively selecting strategies suitable for
          various market conditions <br />• Build and convert long/short
          positions anytime by investing in derivatives such as perpetual
          contracts and swaps
        </p>
      </StrategyItemDesc>
      <StrategyItemDesc>
        <h4>AI-Driven Digital Asset Management</h4>
        <h2>Key Elements and Competitiveness of AI Strategy (2)</h2>
        <div>AI Technology-Based Trading Operation</div>
        <p>
          • Fully automated operation without human intervention in the entire
          process from data collection to investment signal generation, signal
          weight determination, and order execution <br />• Algorithm order
          processing for each strategy and account in consideration of
          situations such as exchange failure and slippage
        </p>
      </StrategyItemDesc>
      <SecondItem />
      <ThirdItem />
      <StrategyItemDesc>
        <h4>AI-Driven Digital Asset Management</h4>
        <h2>Key Elements and Competitiveness of AI Strategy (3)</h2>
        <div>Continuous Evolution of AI Models</div>
        <p>
          • A single platform supports data analysis, portfolio strategy
          development, and algorithmic trading based on a standard verification
          process, which enables concentrating on data analysis and investment
          model research that increase the Sharpe ratio, the essential value of
          asset management. <br />• Respond to market changes by continuous
          analysis, research and supplementation of new data, new AI technology,
          and strategic ideas
        </p>
      </StrategyItemDesc>
    </DesktopStrategyWrapper>
  );
};
