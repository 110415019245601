import styled from "styled-components";

export const IntroduceWrapper = styled.div`
  display: flex;
  padding-top: calc(100vw * 0.12);
  padding-bottom: calc(100vw * 0.1);

  margin: 0 -45px;

  & > div {
    width: 50%;
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const TabletIntroduceWrapper = styled(IntroduceWrapper)`
  display: block;
  margin: 0 -45px;
  & > div {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const MobileIntroduceWrapper = styled(IntroduceWrapper)`
  display: block;
  margin: 0;

  & > div {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const IntroduceBgImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/img/quantfolio2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const IntroduceTextWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  & > p {
    font-weight: 300;
    padding-bottom: 1.5em;
  }

  & > p:last-child {
    padding-bottom: 0;
  }

  & > h2 {
    margin-bottom: 24px;
  }
`;

export const IntroduceImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const MobileIntroduceImageWrapper = styled(IntroduceImageWrapper)`
  height: 360px;
  margin-bottom: 25px;

  @media (max-width: 480px) {
    height: 340px;
  }
`;
